@keyframes pianoEnvelope {
    0% {
      opacity: 1;
      height: 20px;
      box-shadow: 0 0 10px #4da6ff, 0 0 20px #4da6ff, 0 0 30px #4da6ff;
    }
    10% {
      opacity: 0.8;
      height: 15px;
      box-shadow: 0 0 8px #4da6ff, 0 0 16px #4da6ff, 0 0 24px #4da6ff;
    }
    100% {
      opacity: 0;
      height: 5px;
      box-shadow: 0 0 2px #4da6ff, 0 0 4px #4da6ff, 0 0 6px #4da6ff;
    }
  }
  
  .key {
    position: relative;
    overflow: visible !important;
  }
  
  .key::before {
    content: '';
    position: absolute;
    bottom: 0px; /* Position it below the key */
    left: 0;
    right: 0;
    height: 100px;
    border-radius: 20px;
    background-color: #4da6ff;
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
  }
  
  .key.glow::before {
    animation: pianoEnvelope 1s ease-out;
  }
  
  /* Adjust the glow position for black keys */
  .key.black-key::before {
    bottom: -6px; /* Position it closer to the key for black keys */
    height: 5px;
  }